// swipable galleries
import Swiper, { EffectCoverflow } from 'swiper/bundle';
// mailchimp subscribe wrapper
import { sub } from './chimp.js';
// video/audio player
import Plyr from 'plyr';

// import './deal-with-third-party-scripts-and-embeds';

const plyrOptions = {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen']
}

const swiperOptions = {
    common: {
        slidesPerView: 'auto',
        loop: true,
    },
    gallery: {
        slidesPerView: 'auto',
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        loop: true,
        // looopedSlides: 0,
        grabCursor: true,
        lazy: true,
        preloadImages: false,
        watchSlidesVisibility: true,
        // mousewheel: {
        //     forceToAxis: true,
        //     sensitivity: 0.5,
        // },
        // mousewheel: false,
        mousewheel: {
            forceToAxis: true,
        },
        freeMode: false,
        on: {
            lazyImageReady: (swiper) => {swiper.update()},
            // slideChange: (swiper) => {swiper.updateCaptions(swiper)}
        },
        // effect: 'coverflow',
        coverflowEffect: {
            slideShadows: true,
            rotate: 0,
            stretch: 50,
            depth: 200,
            modifier: 3,
        },
        zoom: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        breakpoints: {
            650: {
                slidesOffsetBefore: 200
            }
        }
    },
    vertical: {
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 20,
        freeMode: false,
        direction: 'vertical',
        mousewheel: true,
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        // },
    },
    normal: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        slidesPerView: 'auto',
        loop: false,
        grabCursor: true,
        mousewheel: {
            forceToAxis: true,
        },
        freeMode: true,
        breakpoints: {
            650: {
                slidesOffsetBefore: 0
            }
        }
    }
}

const updateCaptions = (swiper, index) => {
    let captions = document.querySelector('.swiper-captions-container').querySelectorAll('.swiper-caption');
    captions.forEach((caption) => {
        if(caption.dataset.captionFor == index) {
            caption.classList.add('is-visible');
        } else {
            caption.classList.remove('is-visible');
        }
    })
}

const toggleMenu = (e) => {
    let menu = document.querySelector('#main-menu');
    let button = document.querySelector('#menu-button');
    if (menu.classList.contains('is-open')) {
        menu.classList.remove('is-open');
        document.body.classList.remove('is-overlayed');
        button.classList.remove('c-site-header__nav-button--close')
        button.querySelector('#toBars').beginElement();
    } else {
        menu.classList.add('is-open');
        document.body.classList.add('is-overlayed');
        button.classList.add('c-site-header__nav-button--close')
        button.querySelector('#toCross').beginElement();
    }
}

const addListeners = () => {
    let menuButton = document.querySelector('#menu-button');
    if (menuButton) {
        menuButton.addEventListener('click', (e) => {
            toggleMenu(e);
        })
    };

    let nl = document.querySelector('#newsletter');
    if (nl) {
        nl.addEventListener('submit', (e) => {
            sub(e);
        });
    };

    let accordion = document.querySelectorAll('.accordion');
    if (accordion) {
        accordion.forEach(acc => {
            console.log(acc);
            let button = acc.querySelector('button.toggle-accordion');
            if (!button) return;
            button.addEventListener('click', e => {
                if (acc.classList.contains('is-open')) {
                    acc.classList.remove('is-open');
                    button.innerText = button.dataset.textWhenClosed;
                } else {
                    acc.classList.add('is-open');
                    if (button.dataset.textWhenClosed.length == 0) {
                        button.dataset.textWhenClosed = button.innerText;
                    }
                    button.innerText = button.dataset.textWhenOpen;
                }
            })
        })
    }
}

const initSwipers = () => {
    const swiperContainers = document.querySelectorAll('.swiper-container');
    const swipers = [];
    swiperContainers.forEach((swiperContainer) => {
        let swiper;
        if (swiperContainer.dataset.swiperType == 'gallery') {
            swiper = new Swiper(swiperContainer, swiperOptions.gallery);
        } else if (swiperContainer.dataset.swiperType == 'vertical') {
            console.log(swiperContainer.dataset.swiperOffset);
            swiper = new Swiper(swiperContainer, {...swiperOptions.vertical});
        } else {
            swiper = new Swiper(swiperContainer, swiperOptions.normal);
        }
        swiper.updateCaptions = () => {
            let captionsContainer = document.querySelector(`[data-captions-for="${swiper.el.dataset.id}"]`);
            if (!captionsContainer) return;
            let captions = captionsContainer.querySelectorAll(`[data-caption-index]`);
            if (captions) {
                captions.forEach((caption) => {
                    if (caption.dataset.captionIndex == swiper.realIndex) {
                        caption.classList.add('is-visible');
                    } else {
                        caption.classList.remove('is-visible');
                    }
                })
            }
        }
        swiper.on('slideChange', () => {
            swiper.updateCaptions(swiper.realIndex);
        })
        swipers.push(swiper);
    })
    return swipers;
}

const init = () => {
    addListeners();
    const playerElement = document.querySelector('#player');
    const swipers = initSwipers();
    if (playerElement) {
        const player = new Plyr('#player', plyrOptions);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    init();
})